import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_BANNER,
  ADD_BANNER_DATA,
  UPDATE_BANNER_DATA,
  ACTIVATE_BANNER
} from "./actionTypes"


import {
  addBannerDataSuccess,
  addBannerDataFail,
  getBannerDataFail,
  getBannerDataSuccess
} from "./actions"

import { toast } from "react-toastify"

// Include Both Helper File with needed methods
import {
  getBannerList,
  addBannerList,
  updateBannerList,
  activateBanner
} from "helpers/backend_helper"

// banner
function* fetchBanner() {
  try {
    const response = yield call(getBannerList)
    yield put(getBannerDataSuccess(response))
  } catch (error) {
    yield put(getBannerDataFail(error))
  }
}

// add data 
function* onAddBannerData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("title",data.title)
    formData.append("body", data.body)
    formData.append("product_id", data.product_id)
    formData.append("banner_image", data.fileImage[0])
    const response = yield call(addBannerList, formData)
    yield put(addBannerDataSuccess(response))
    yield put({type:"GET_BANNER"})
    toast.success("Banner Added Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addBannerDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}

// UPDATE BANNER 
function* onUpdateBannerData({ payload: data }) {
  try {
    const formData = new FormData()
    formData.append("id", data.id)
    formData.append("title", data.title)
    formData.append("body", data.body)
    formData.append("product_id", data.product_id)
    formData.append("banner_image", data.fileImage[0])
    const response = yield call(updateBannerList, formData)
    yield put({type:"GET_BANNER"})
    toast.success("Banner Updated Successfully", { autoClose: 5000 });
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addBannerDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


// activate Banner api
function* onActivateBanner({ payload: data }) {
  try {
    const response = yield call(activateBanner, data)
    yield put({type:"GET_BANNER"})
  } catch (error) {
    const message = error.response.data.name ? error.response.data.name[0] : (error.detail ? error.detail : "System error"); 
    yield put(addBannerDataFail(message))
    toast.error(message, { autoClose: 5000 });
  }
}


function* BannerSaga() {
  yield takeEvery(GET_BANNER, fetchBanner)
  yield takeEvery(ACTIVATE_BANNER, onActivateBanner)
  yield takeEvery(ADD_BANNER_DATA, onAddBannerData)
  yield takeEvery(UPDATE_BANNER_DATA, onUpdateBannerData)
}

export default BannerSaga
