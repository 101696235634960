import React, { useState, useMemo, useEffect, useRef } from "react"
import { createSelector } from "reselect"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container,
  FormFeedback,
  UncontrolledTooltip,
  Form,
} from "reactstrap"
import { Image, Status, LongText, CreateadAt } from "../categorytCol"
import { ToastContainer } from "react-toastify"

import TableContainer from "../../../components/Common/TableContainer"
import Dropzone from "react-dropzone"

import { Link } from "react-router-dom"

import {
  addBannerData as onAddBannerData,
  getBanner as onGetBanner,
  activateBanner as onActivateBanner,
  updateBannerData as onUpdateBannerData,
} from "store/setting/banner/actions"


import { getProduct as onGetProduct } from "store/product/ProductList/actions"  

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// redux
import { useSelector, useDispatch } from "react-redux"

import DeleteModal from "../DeleteModal"

const Banner = args => {
  //meta title
  document.title = "WekaDau | Banner"

  //  dispatch
  const dispatch = useDispatch()

  // fetch data for the group
  useEffect(() => {
    dispatch(onGetBanner())
    dispatch(onGetProduct())
  }, [dispatch])

  const [banner, setBanner] = useState()
  const [isEdit, setIsEdit] = useState(false)
  // delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [banners, setBanners] = useState([])
  const [products, setProducts] = useState([])
  const [banner_data, setBannerData] = useState({})

  //  modal
  const [modal, setModal] = useState(false)
  const [modalActivate, setModalActivate] = useState(false)

  const onClickDelete = banner => {
    setBanner(banner)
    setDeleteModal(true)
  }

  const selectGroupState = state => state.banner
  const selectProductState = state => state.Product


  const BannerProperties = createSelector(selectGroupState, Banner => ({
    BannerData: Banner.data,
    loading: Banner.loading,
  }))

  const ProductProperties = createSelector(selectProductState, Product => ({
    ProductData: Product.data,
    loading: Product.loading,
  }))

  const { BannerData, loading } = useSelector(BannerProperties)
  const { ProductData, productLoading } = useSelector(ProductProperties)
  const [selectedFiles, setselectedFiles] = useState([])
  const [errorFiles, seterrorFiles] = useState("")

  useEffect(() => {
    setBanners(BannerData ? BannerData.data : [])
  }, [BannerData])

  // product data
  useEffect(() => {
    setProducts(ProductData ? ProductData.data : [])
  }, [ProductData])

  const handleGroupClick = arg => {
    const banner = arg
    setBanner({
      id: banner.id,
      title: banner.title,
      body: banner.body,
      image: banner.banner_image,
    })
    setIsEdit(true)
    toggle()
  }

  const handleActivate = arg => {
    const data = arg
    setBannerData(data)
    toggleActivate()
  }


  const handleAddBanner = arg => {
    const data = arg
     toggle()
  }

  // handle delete group
  const handleDeleteGroup = () => {
    if (banner && banner.id) {
      console.log(banner.id)
      // dispatch(onDeleteUser(group.id));
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const toggle = () => {
    if (modal) {
      // setGroup()
      setIsEdit(false)
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const toggleActivate = () => {
    if (modalActivate) {
      setModalActivate(false)
    } else {
      setModalActivate(true)
    }
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  function handleAcceptedFiles(files) {
    files.map(file => {
      if (file.type.startsWith("image/")) {
        setselectedFiles(files)
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      } else {
        seterrorFiles("Please image select files")
        // handle non-image files here or simply ignore them
        return null // returning null will filter out non-image files from the resulting array
      }
    })
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      banner_body: (banner && banner.body) || "",
      banner_title: (banner && banner.title) || "",
      featured_product: (banner && banner.featured_product) || "",
    },
    validationSchema: Yup.object({
      banner_body: Yup.string().required("Please Enter Body"),
      banner_title: Yup.string().required("Please Enter Title"),
    }),

    onSubmit: values => {
      if (isEdit) {
        const data = {
          body: values.banner_body,
          title: values.banner_title,
          product_id: values.featured_product,
          fileImage: selectedFiles,
          id: banner.id,
        }
       
      //  console.log(data) 
       dispatch(onUpdateBannerData(data))
      } else {
        let payload = {
          body: values.banner_body,
          title: values.banner_title,
          product_id: values.featured_product,
          fileImage: selectedFiles,
        }
        dispatch(onAddBannerData(payload))
      }
      validation.resetForm()
      toggle()
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Body",
        accessor: "body",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <LongText {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Status {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Image",
        accessor: "banner_image",
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Image {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Created at",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <CreateadAt {...cellProps} />{" "}
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-info"
                onClick={() => {
                  const groupData = cellProps.row.original
                  handleGroupClick(groupData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
                Edit
              </Link>

              {/* <Button
                type="button"
                color="primary"
                className="btn mt-2 me-1"
                onClick={handleActivate(cellProps.row.original)}
              >
                <i className="bx bx-check me-2" />{" "}
                {cellProps.row.original.is_active ? "Activate" : "Deactivate"}
              </Button> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const groupData = cellProps.row.original
                  handleActivate(groupData)
                }}
              >
                <i
                  className="mdi mdi-sticker-check-outline font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  {cellProps.row.original.is_active ? "Deactivate" : "Activate"}
                </UncontrolledTooltip>
                {cellProps.row.original.is_active ? "Deactivate" : "Activate"}
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const handleSubmitApprove = () => {
    if (banner_data.is_active === true) {
      const payload = {
        banner_id: banner_data.id,
        banner_status: true
      }
      dispatch(onActivateBanner(payload))
      toggleActivate()
    } else {
      const data = {
        banner_id: banner_data.id,
        banner_status: false
      }
      dispatch(onActivateBanner(data))
      toggleActivate()
    }
  }

  const regExp = /\b\d{5}\b/
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Banner" breadcrumbItem="Banner List" />
          <Row>
            <Col xl="3">
              <Card>
                <CardBody>
                  <div className="text-right d-grid">
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light addtask-btn"
                      data-bs-toggle="modal"
                      data-bs-target=".bs-example-modal-lg"
                      data-id="#upcoming-task"
                      onClick={() => handleAddBanner(true)}
                    >
                      <i className="mdi mdi-plus me-1"></i> Add Banner
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="9">
              <Card>
                <CardBody>
                  <div className="container-fluid">
                    <TableContainer
                      columns={columns}
                      data={banners}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      isPagination={true}
                      tableClass="align-middle table-nowrap table-check table"
                      theadClass="table-light"
                      paginationDiv="col-12"
                      pagination="justify-content-center pagination pagination-rounded"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* modal */}
      <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="md"
      >
        <ModalHeader toggle={toggle}>
          {!!isEdit ? "Edit Banner" : "Add Banner"}
        </ModalHeader>
        <ModalBody>
          <Form
            className="needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col md="12" sm="12" lg="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="validationCustom01">Banner Title</Label>
                  <Input
                    name="banner_title"
                    placeholder="Banner Title"
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.banner_title || ""}
                    invalid={
                      validation.touched.banner_title &&
                      validation.errors.banner_title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.banner_title &&
                  validation.errors.banner_title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.banner_title}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup className="mb-3 mt-2">
                  <Label htmlFor="validationCustom01">Banner Body</Label>
                  <Input
                    name="banner_body"
                    placeholder="Banner Body"
                    type="textarea"
                    maxLength="225"
                    rows="4"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.banner_body || ""}
                    invalid={
                      validation.touched.banner_body &&
                      validation.errors.banner_body
                        ? true
                        : false
                    }
                  />
                  {validation.touched.banner_body &&
                  validation.errors.banner_body ? (
                    <FormFeedback type="invalid">
                      {validation.errors.banner_body}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                {!!isEdit ? (
                  <div>
                    <img height="70" src={banner.image} alt="image" />
                  </div>
                ) : (
                  ""
                )}

   {/* select for the products*/}
          <FormGroup className="mb-3">
               <Label htmlFor="validationCustom01">Featured Product (If available)</Label>
                  <Input
                    name="featured_product"
                    placeholder="Featured Product"
                    type="select"
                    className="form-control"
                    id="validationCustom01"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.featured_product || ""}
                    invalid={
                      validation.touched.featured_product &&
                      validation.errors.featured_product
                        ? true
                        : false
                    }
                  >
                  <option value="">Select Product</option>
                  {products.map((item, key) => {
                    return (
                      <option value={item.id} key={key}>
                        {item.name}
                      </option>
                    )
                  })}
                  </Input>
                  {validation.touched.featured_product &&
                  validation.errors.featured_product ? (
                    <FormFeedback type="invalid">
                      {validation.errors.featured_product}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
   {/* select for the products*/}

                <FormGroup className="mb-3">
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop banner image here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                    <span className="text-danger">
                      {errorFiles ? errorFiles : ""}*
                    </span>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Button color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      {/* modal */}

      {/* model for activate the banner */}
      <div>
        <Modal isOpen={modalActivate} toggle={toggleActivate} {...args}>
          <ModalHeader toggle={toggleActivate}>Approve Banner</ModalHeader>
          <ModalBody>
            Are you sure you want to approve this banner:{" "}
            <b>{banner_data ? banner_data.title : ""}</b> ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmitApprove}>
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={toggleActivate}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {/* model for activate  banner */}
      <ToastContainer />
    </React.Fragment>
  )
}

export default Banner
