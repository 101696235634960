import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom";
import * as moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import classnames from "classnames";
import { isEmpty } from "lodash";
import TableContainer from "../../components/Common/TableContainer"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getAuctionOrderDetails as onGetAuctionsDetails } from "store/Bidding/AuctionBiddingList/actions"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';

import {
  CreateadAt,
  Customer,
  OrderNumber,
  TotalWarningNumber,
  PriceFormat,
} from "../../components/Common/Col"

const AuctionDetails = (args) => {

  //meta title
  document.title = "Wekadau | Auction Order Details";

  let { auction_id } = useParams();
  //  dispatch
  const dispatch = useDispatch();

  const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  useEffect(() => {
    if (auction_id) {
      let data = {
        auction_id: auction_id,
      }
      dispatch(onGetAuctionsDetails(data))
    } 
  }, [dispatch]);


  const [activeTab, setActiveTab] = useState(1); 

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const selectAuctionState = (state) => state.Auction;

  const AuctionProperties = createSelector(selectAuctionState, Auction => ({
    Auction: Auction ? Auction.data : {},
    loading: Auction
  }))

  const { Auction, loading } = useSelector(AuctionProperties)

  const [isLoading, setLoading] = useState(loading)

  const [auctions, setAuction] = useState({})
  const [auctionsBidder, setAuctionBidder] = useState([])

  useEffect(() => {
    setAuction(Auction ? Auction.data : {})
    setAuctionBidder(Auction ? Auction.data ? Auction.data.auction_bidder  :[] : [])
  }, [Auction])


const columns = useMemo(
  () => [
    {
      Header: "Auctioneer",
      accessor: "customer",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <Customer {...cellProps} />{" "}
          </>
        )
      },
    },
    {
      Header: "Auction Number",
      accessor: "auction_number",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <OrderNumber {...cellProps} />{" "}
          </>
        )
      },
    },

    {
      Header: "Quantity",
      accessor: "quantity",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <TotalWarningNumber {...cellProps} />{" "}
          </>
        )
      },
    },
    {
      Header: "Auction Price",
      accessor: "auction_price",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <PriceFormat {...cellProps} />{" "}
          </>
        )
      },
    },
    {
      Header: "Total Price",
      accessor: "total_price",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <PriceFormat {...cellProps} />{" "}
          </>
        )
      },
    },
    {
      Header: "Auction_date",
      accessor: "created_at",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <CreateadAt {...cellProps} />{" "}
          </>
        )
      },
    },
   
    {
      Header: "Action",
      Cell: cellProps => {
        return (
          <div className="d-flex gap-3">
            <Link to="#" className="text-success ">
              <Button color="success" outline>
                View
                <i className="mdi mdi-eye font-size-18 m-1" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                 View
                </UncontrolledTooltip>
              </Button>
            </Link>
          </div>
        )
      },
    },
  ],
  []
)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Auction Details"
            breadcrumbItem="Auction Details"
          />

          {/* header  */}
          {!isEmpty(auctions) && (
            <Row>
              <Col xl="5">
                {" "}
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md={2} sm={3} className="col-4">
                              <Nav className="flex-column" pills>
                                <NavItem>
                                  {auctions.product.product_image.map(
                                    (product, index) => (
                                      <NavLink
                                        key={product.id}
                                        className={classnames({
                                          active: activeTab === index + 1,
                                        })}
                                        onClick={() => {
                                          toggleTab(index + 1)
                                        }}
                                      >
                                        <img
                                          src={product.Product_image}
                                          alt=""
                                          onClick={() => {
                                            imageShow(
                                              products.product_image[index],
                                              index
                                            )
                                          }}
                                          className="img-fluid mx-auto d-block rounded"
                                        />
                                      </NavLink>
                                    )
                                  )}
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md={7} sm={9} className="offset-md-1 col-8">
                              <TabContent activeTab={activeTab}>
                                {auctions.product.product_image.map(
                                  (product, index) => (
                                    <div key={product.id}>
                                      {index + 1 === activeTab ? (
                                        <div>
                                          <img
                                            src={product.Product_image}
                                            alt={product.description}
                                            id={`expandedImg${index + 1}`}
                                            className="img-fluid mx-auto d-block"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )
                                )}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="6">
                <Row>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <Row>
                        <Col xl="6" sm="6" md="6">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div className="mt-4 mt-xl-3">
                                <span className="text-muted mb-4">
                                  Bidding Number:
                                  <h5 className="mt-1 mb-3">
                                    {auctions.bidding_number}
                                  </h5>
                                </span>

                                <span>
                                  Total Auctions :
                                  <h5 className="mt-1 mb-3">
                                    {auctions.total_auction}
                                  </h5>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl="6" sm="6" md="6">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div className="mt-4 mt-xl-3">
                                <span className="text-muted mb-4">
                                  Type of the Bid:
                                  <h5 className="mt-1 mb-3">
                                    {auctions.type_of_bid}
                                  </h5>
                                </span>

                                <span>
                                  Maximum Auction Price :
                                  <h5 className="mt-1 mb-3">
                                    {priceSplitter(
                                      auctions.latest_max_auction_price
                                    )}
                                  </h5>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
          )}
          {/* header  */}
          {/* header down */}
          {!isEmpty(auctions) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12">
                        <Row>
                          <Col xl="3" sm="6" md="4">
                            <div className="mt-4 mt-xl-3">
                              <span className="text-muted mb-4">
                                Product Name :
                                <h5 className="mt-1 mb-3">
                                  {auctions.product.name}
                                </h5>
                              </span>

                              <span>
                                Product Number :
                                <h5 className="mt-1 mb-3">
                                  {auctions.product.product_number}
                                </h5>
                              </span>
                              <p className=" mb-4">
                                Uploaded By :{" "}
                                <h4>
                                  {auctions.product.customer
                                    ? auctions.product.customer.details
                                        .first_name +
                                      " " +
                                      auctions.product.customer.details
                                        .middle_name +
                                      " " +
                                      auctions.product.customer.details
                                        .last_name
                                    : ""}
                                </h4>
                              </p>
                            </div>
                          </Col>
                          <Col xl="3" sm="6" md="4">
                            <div className="mt-4 mt-xl-3">
                              <p>
                                Brand :
                                <h5 className="mt-1 mb-3">
                                  {auctions.product.brand}
                                </h5>
                              </p>

                              <p>
                                Starting Price :
                                <h5 className="mt-1 mb-3">
                                  {priceSplitter(
                                    auctions.bidding_price.starting_price
                                  )}{" "}
                                  {auctions.bidding_price.currency}
                                </h5>
                              </p>
                              <p>
                                Total Product :
                                <h5 className="mt-1 mb-3">
                                  {auctions.product.quantity}
                                </h5>
                              </p>
                            </div>
                          </Col>
                          <Col xl="3" sm="6" md="4">
                            <p>
                              Ending Price :
                              <h5 className="mt-1 mb-3">
                                {priceSplitter(
                                  auctions.bidding_price.ending_price
                                )}{" "}
                                {auctions.bidding_price.currency}
                              </h5>
                            </p>

                            <p>
                              Starting date:
                              <h5 className="mt-1 mb-3">
                                {formateDate(auctions.starting_date)}
                              </h5>
                            </p>

                            <p>
                              Ending date:
                              <h5 className="mt-1 mb-3">
                                {formateDate(auctions.ending_date)}
                              </h5>
                            </p>
                          </Col>
                          <Col xl="3" sm="6" md="4">
                            <p>
                              Condition :
                              <h5 className="mt-1 mb-3">
                                {auctions.product.condition}
                              </h5>
                            </p>
                            <p>
                              Sub category :
                              <h5 className="mt-1 mb-3">
                                {auctions.product.subcategory
                                  ? auctions.product.subcategory.name
                                  : ""}
                              </h5>
                            </p>

                            <p>
                              Uploaded at :
                              <h5 className="mt-1 mb-3">
                                {formateDate(auctions.product.created_at)}
                              </h5>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* header down */}

          <div>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (

                  <div>
                   <Row>
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <div className="container-fluid">
                        <TableContainer
                          columns={columns}
                          data={auctionsBidder ? auctionsBidder : []}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          isPagination={true}
                          tableClass="align-middle table-nowrap table-check table"
                          theadClass="table-light"
                          paginationDiv="col-12"
                          pagination="justify-content-center pagination pagination-rounded"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row> 
                  </div>



              
            )}
            <ToastContainer />
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
};
export default AuctionDetails
