import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  FormFeedback,
  ButtonGroup

} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";

//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  approveProduct,
  featuredProduct,
  rejectedProduct,
  unFeaturedProduct,
  getProductDetails as onGetProductDetail
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useParams } from 'react-router-dom';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


// Form Editor
import EditFormStepper from './CustomerProducts/EditFormWizard/Form';

const ProductDetail = (args) => {

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  //meta title
  document.title = "Wekadau | Product details";

  let { product_id } = useParams();
  //  dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (product_id) {
      let data = {
        "product_id":product_id
      };
      dispatch(onGetProductDetail(data))
    } 
  }, [dispatch]);


  const selectProductState = (state) => state.Product;
  const ProductsProperties = createSelector(
  selectProductState,
    (Product) => ({
      product: Product ? Product.data :{},
    })
  );

  const {
    product,
  } = useSelector(ProductsProperties);

  const [products, setProducts] = useState({})

  useEffect(() => {
  setProducts(product ? product.data:{});
}, [product])

  const [activeTab, setActiveTab] = useState(1); 

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }imageShow
  };

  const imageShow = (img, id) => {
    console.log(img)
    console.log(id)
    // const expandImg = document.getElementById("expandedImg" + id);
    // expandImg.src = img.Product_image;
  };

   const [modal, setModal] = useState(false);
   const [modalreject, setModalReject] = useState(false);
   const [modalfeatured, setModalFeatured] = useState(false);
   const [modalEdit, setModalEdit] = useState(false);
   const [modalunfeatured, setModalunFeatured] = useState(false);

  const toggle = () => setModal(!modal);
  const togglereject = () => setModalReject(!modalreject);
  const togglefeatured = () => setModalFeatured(!modalfeatured);
  const editProduct = () => setModalEdit(!modalEdit);
  const toggleunfeatured = () => setModalunFeatured(!modalunfeatured);

  const handleSubmitApprove = () =>{
    const payload = {
      'product_id':products.id
    }
    dispatch(approveProduct(payload))
    toggle()
  }

  const closeModal = () => {
    setModalEdit(false);
    };

  const handleSubmitFeatured = () =>{
    const payload = {
      'product_id':products.id
    }
    dispatch(featuredProduct(payload))
    togglefeatured()
  }

  const handleSubmitUnFeatured = () =>{
    const payload = {
      'product_id':products.id
    }
    dispatch(unFeaturedProduct(payload))
    toggleunfeatured()
  }

   // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reject_reason:  ""
    },
    validationSchema: Yup.object({
      reject_reason: Yup.string().required("Please Enter  Reject Reason"),
    }),
    onSubmit: (values) => {
        const payload={
           'reject_reason':values.reject_reason,
           "product_id": products.id
         }
        dispatch(rejectedProduct(payload));
        validation.resetForm()
        togglereject()
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Products" breadcrumbItem="Product Detail" />
          {!isEmpty(products) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={6}>
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md={2} sm={3} className="col-4">
                              <Nav className="flex-column" pills>
                                <NavItem>
                                  {products.product_image.map(
                                    (product,index)=>(
                                  <NavLink key={product.id}
                                    className={classnames({
                                      active: activeTab === index +1,
                                    })}
                                    onClick={() => {
                                      toggleTab(index +1 );
                                    }}
                                  >
                                    <img
                                      src={product.Product_image}
                                      alt=""
                                      onClick={() => {
                                        imageShow(
                                          products.product_image[index],
                                          index
                                        );
                                      }}
                                      className="img-fluid mx-auto d-block rounded"
                                    />
                                  </NavLink>
                                   )
                                  )}
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md={7} sm={9} className="offset-md-1 col-8">
                              <TabContent activeTab={activeTab}>
                                {products.product_image.map(
                                    (product,index)=>(
                                  <div key={product.id} >
                                  {index + 1 === activeTab ? (<div>
                                     <img
                                      src={product.Product_image}
                                      alt={product.description}
                                      id={`expandedImg${index + 1}`}
                                      className="img-fluid mx-auto d-block"
                                    />
                                  </div>):""}         
                                  </div>
                                 )
                                  )}
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xl="6">
                        <Row>
                          <Col>
                          <div className="mt-4 mt-xl-3"> 
                          <p className="text-muted mb-4">
                            Product Name :<h5 className="mt-1 mb-3">{products.name}</h5> 
                          </p>
                          <div className="text-muted float-start me-3">
                            {/* <StarRatings
                              rating={4}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#74788d"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            /> */}
                          </div>
                          <p className="text-muted mb-4">
                            Descriptions :<h5 className="mt-1 mb-3">{products.description}</h5> 
                          </p>
                          <p>
                            Product Number :
                          <h5 className="mt-1 mb-3">{products.product_number}</h5>
                          </p>

                          <p>
                            Brand :
                          <h5 className="mt-1 mb-3">{products.brand}</h5>
                          </p>

                          <p>
                            Condition :
                          <h5 className="mt-1 mb-3">{products.condition}</h5>
                          </p>
                          
                          </div>
                          </Col>
                          <Col></Col>
                        </Row>
                        <div className="mt-4 mt-xl-3">                          
                          
                          <p>
                            Category :
                          <h5 className="mt-1 mb-3">{products.subcategory.category ? products.subcategory.category.name :''}</h5>
                          </p>
                      
                          <p>
                            Sub category :
                          <h5 className="mt-1 mb-3">{products.subcategory ? products.subcategory.name :''}</h5>
                          </p>

                          <p>
                            Uploaded at :
                          <h5 className="mt-1 mb-3">{formateDate(products.created_at)}</h5>
                          </p>

                          <p>
                            Total Product :
                          <h5 className="mt-1 mb-3">{products.quantity}</h5>
                          </p>
                          {/* <h5 className="mb-4">
                            Price Range :{" "}
                            <span>
                            <b>
                              {products.product_price ? priceSplitter(products.product_price.starting_price) :""}   {products.product_price ? products.product_price.currency :""}
                              </b>  
                            </span>{" - "}
                            <b>{products.product_price ? priceSplitter(products.product_price.ending_price) :""}   {products.product_price ? products.product_price.currency :""} </b>
                          </h5> */}
                          <p className=" mb-4">
                           Uploaded By : <h4>
                            {products.customer ? products.customer.details.first_name + " " + products.customer.details.middle_name  + " " + products.customer.details.last_name:""}
                            </h4> 
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <h5 className="mb-3">Specifications :</h5>
                      <div className="table-responsive">
                        {products.specifications}
                      </div>
                    </div>
                    {products.rejection_reason.length >= 1 ? (
                      <div className="mt-5">
                      <h5 className="mb-3 text-danger">Rejected Reason:</h5>
                      <div className="table-responsive text-danger">
                        {products.rejection_reason.length >= 1 ? 
                        products.rejection_reason.map((product,index)=>
                          (
                            <div key={product.id}>
                              {index+1} :  { product.reason} <br/>
                            </div>
                          )
                          ):""
                      }
                      </div>
                    </div>
                    ) :""}
                    

                    <ButtonGroup>
                       {!isEmpty(products) && (
                        <div>
                        {products.is_active ? (
                            <Button
                                  type="button"
                                  color="danger"
                                  className="btn mt-2 me-1"
                                  onClick={togglereject}
                                >
                                  <i className="bx bx-x me-2" /> Reject Product
                                </Button>):
                                (
                                  <div>
                                   <Button
                                  type="button"
                                  color="primary"
                                  className="btn mt-2 me-1"
                                  onClick={toggle}
                                >
                                  <i className="bx bx-check me-2" /> Approve Product
                                </Button>  

                                <Button
                                  type="button"
                                  color="danger"
                                  className="btn mt-2 me-1"
                                  onClick={togglereject}
                                >
                                  <i className="bx bx-x me-2" /> Reject Product
                                </Button>


                                  </div>
                                 
                                )
                                }
                                {products.is_featured ? (
                                <Button
                                  type="button"
                                  color="info"
                                  className="ms-1 btn mt-2"
                                  onClick={toggleunfeatured}
                                >
                                  <i className="bx bx-minus me-2" />
                                  UnFeatured Product
                                </Button>):
                              (<Button
                                  type="button"
                                  color="success"
                                  className="ms-1 btn mt-2"
                                  onClick={togglefeatured}
                                >
                                  <i className="bx bx-info-circle me-2" />
                                  Featured Product
                             </Button>)}
                             <Button
                                  type="button"
                                  color="success"
                                  className="ms-1 btn mt-2"
                                  onClick={editProduct}
                                >
                                  <i className="bx bx-edit me-2" />
                                  Edit Product
                             </Button>
                        </div>
                       )}
                    </ButtonGroup>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
        {/* model for approve the products */}
        <div>
          <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Approve Product</ModalHeader>
        <ModalBody>
         Are you sure you want to approve this Product name: <b>{products? products.name :""}</b> ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitApprove}>
            Submit
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
        </div>
        {/* model for approve the products */}
        
        {/* model for reject the products */}
        <div>
          <Modal isOpen={modalreject} toggle={togglereject} {...args}>
        <ModalHeader toggle={togglereject}>Reject Product</ModalHeader>
        <ModalBody>
         Are you sure you want to reject this Product name: <b>{products? products.name :""}</b> ?
          <Form className="needs-validation"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col md="12" sm="12" lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Reject Reason</Label>
                          <Input
                            name="reject_reason"
                            placeholder="Reject Reason"
                            type="textarea"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.reject_reason || ""}
                            invalid={
                              validation.touched.reject_reason && validation.errors.reject_reason ? true : false
                            }
                          />
                          {validation.touched.reject_reason && validation.errors.reject_reason ? (
                            <FormFeedback type="invalid">{validation.errors.reject_reason}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglereject}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
        </div>
        {/* model for rejects the products */}

        {/* model for featured the products */}
        <div>
          <Modal isOpen={modalfeatured} toggle={togglefeatured} {...args}>
        <ModalHeader toggle={togglefeatured}>Featured Product</ModalHeader>
        <ModalBody>
         Are you sure you want to make  this Product name: <b>{products? products.name :""}  </b>  as featured?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitFeatured}>
            Submit
          </Button>{' '}
          <Button color="secondary" onClick={togglefeatured}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
        </div>
        {/* model for featured the products */}

        {/* model for unfeatured the products */}
        <div>
          <Modal isOpen={modalunfeatured} toggle={toggleunfeatured} {...args}>
        <ModalHeader toggle={toggleunfeatured}>Remove Product as Featured</ModalHeader>
        <ModalBody>
         Are you sure you want to remove  this Product name: <b>{products? products.name :""}  </b>  as featured?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmitUnFeatured}>
            Submit
          </Button>{' '}
          <Button color="secondary" onClick={toggleunfeatured}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
        </div>
        {/* model for featured the products */}


 {/* model for featured the products */}
 <div>
          <Modal 
           centered={true}
           backdrop={true}
           size="xl" 
           isOpen={modalEdit}
            toggle={editProduct} 
            {...args}>
              <ModalHeader toggle={editProduct}>Edit Product</ModalHeader>
              <ModalBody>
               <center> Edit this Product <b>{products? products.name :""}  </b> ?</center>
               <EditFormStepper products={products} closeModal={closeModal}></EditFormStepper>
              </ModalBody>  
              <ModalFooter>
                <Button color="secondary" onClick={editProduct}>
                  Cancel
                </Button>
              </ModalFooter>
      </Modal>
        </div>
        {/* model for featured the products */}


      </div>
    </React.Fragment>
  );
};
export default ProductDetail;
