import "./style.scss";
import * as React from "react";
import * as Yup from "yup";
import { FormikWizard } from "formik-wizard-form";
import Steps from "./Steps";
import ProductDetailsForm from "./ProductDetailsForm";
import ImageForm from "./ImageForm";
import CategoryForm from "./CategoryForm";
import {
  editProduct as onEditProductData,
} from "store/product/CustomerProduct/actions";
import { useDispatch } from "react-redux"

export default function App({ closeModal ,products}) {
  //  dispatch
   const dispatch = useDispatch()
  return (
    <div className="container p-4">
      <FormikWizard
        initialValues={{
          name:products.name??"",
          id:products.id??"",
          productModel:products.product_number??"",
          brand:products.brand??"",
          condition:products.condition??"",
          quantity:products.quantity??"",
          description:products.description??"",
          specifications:products.specifications??"",
          category:products.subcategory.category.id??"",
          subcategory:products.subcategory.id??"",
          manufactureYear: products.manufacture_year??"",
          ProductImages: [],
          youtube_link:products.youtube_link??""
        }}
        onSubmit={(values) => {
          dispatch(onEditProductData(values))
          closeModal();
        }}
        validateOnNext
        activeStepIndex={0}
        steps={[
          {
            component: ProductDetailsForm,
            validationSchema: Yup.object().shape({
              name: Yup.string().required("Product name is required"),
              productModel: Yup.string().required("Product Model is required"),
              brand: Yup.string().required("Brand is required"),
              condition: Yup.string().required("Condition is required"),
              quantity: Yup.number().required("Quantity is required")
            })
          },
          {
            component: CategoryForm,
            validationSchema: Yup.object().shape({
              category: Yup.string().required("Category is required"),
              subcategory: Yup.string().required("Sub Category is required")
            })
          }
        ]}
      >
        {({
          currentStepIndex,
          renderComponent,
          handlePrev,
          handleNext,
          isNextDisabled,
          isPrevDisabled,
          isLastStep
        }) => {
          return (
            <>
              <Steps currentStepIndex={currentStepIndex} />
              {renderComponent()}
              <div className="buttons flex justify-content-center">
                {currentStepIndex === 0 ? <></>:<>
                 <button
                  className="button is-primary"
                  disabled={isPrevDisabled}
                  onClick={handlePrev}
                >
                  Previous
                </button>
                </>}
               
                <button
                  className="button is-primary"
                  disabled={isNextDisabled}
                  onClick={handleNext}
                >
                  {isLastStep ? "Submit" : "Next"}
                </button>
              </div>
            </>
          );
        }}
      </FormikWizard>
    </div>
  );
}
