/**PRODUCT LIST**/
export const ADD_PRODUCT_DATA = "ADD_PRODUCT_DATA"
export const ADD_PRODUCT_DATA_FAIL = "ADD_PRODUCT_DATA_FAIL"
export const ADD_PRODUCT_DATA_SUCCESS = "ADD_PRODUCT_DATA_SUCCESS"

/**EDIT PRODUCT DATA**/ 
export const EDIT_PRODUCT_DATA = "EDIT_PRODUCT_DATA"
export const EDIT_PRODUCT_DATA_FAIL = "EDIT_PRODUCT_DATA_FAIL"
export const EDIT_PRODUCT_DATA_SUCCESS = "EDIT_PRODUCT_DATA_SUCCESS"

/**GET PRODUCT**/ 
export const GET_CUSTOMER_PRODUCT = "GET_CUSTOMER_PRODUCT"
export const GET_CUSTOMER_PRODUCT_SUCCESS = "GET_CUSTOMER_PRODUCT_SUCCESS"
export const GET_CUSTOMER_PRODUCT_FAIL = "GET_CUSTOMER_PRODUCT_FAIL"

/*PRODUCT DETAILS*/ 
export const GET_CUSTOMER_PRODUCT_DETAILS = "GET_CUSTOMER_PRODUCT_DETAILS"
export const GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS = "GET_CUSTOMER_PRODUCT_DETAILS_SUCCESS"
export const GET_CUSTOMER_PRODUCT_DETAILS_FAIL = "GET_CUSTOMER_PRODUCT_DETAILS_FAIL"

/* PRODUCT APPROVAL*/ 
export const CUSTOMER_PRODUCT = "CUSTOMER_PRODUCT"

/**GET PRODUCT**/ 
export const GET_CUSTOMER_APPROVED_PRODUCT = "GET_CUSTOMER_APPROVED_PRODUCT"
