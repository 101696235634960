// import "./style.scss";
import * as React from "react";
import * as Yup from "yup";
import { FormikWizard } from "formik-wizard-form";
import Steps from "./Steps";
import BidDetails from "./BidDetails";
import AddressForm from "./Address";
import PriceForm from "./Price";
import {
  addBid as onAddBid,
} from "store/Bidding/CustomerBid/actions";
import { useDispatch } from "react-redux"

export default function App({ closeModal }) {
  /**dispatch**/  
   const dispatch = useDispatch()
   
  return (
    <div className="container p-4">
      <FormikWizard
        initialValues={{
          starting_date: (() => {
            const now = new Date();
            now.setMinutes(now.getMinutes() + 300); // Add 30 minutes
            return now.toISOString().slice(0, 16); // Format to YYYY-MM-DDTHH:MM
        })(),
          type_of_bid:"",
          ending_date: (() => {
            const now = new Date();
            now.setHours(now.getHours() + 7200); // Add 72 hours
            return now.toISOString().slice(0, 16); // Format to YYYY-MM-DDTHH:MM
        })(),
          product_id:"",
          starting_price:"",
          ending_price:"",
          currency:"",
          region_id:"",
          district_id:"",
          ward_id:"",
          street_id: "",
          physical_address:""
        }}
        onSubmit={(values) => {
          dispatch(onAddBid(values));
          closeModal();
        }}
        validateOnNext
        activeStepIndex={0}
        steps={[
          {
            component: BidDetails,
            validationSchema: Yup.object().shape({
              starting_date: Yup.date()
              .required("Start date is required")
              .min(new Date(), "Start date cannot be in the past"),
              ending_date: Yup.date()
              .transform((value, originalValue) => {
                return originalValue ? new Date(originalValue) : value;
            })
              .required("End date is required")
              .when("starting_date", (starting_date,schema) => {
                if (!starting_date) return schema; // If starting_date is not defined, skip the check
                const startDate = new Date(starting_date);
                return schema.min(startDate, "End date must be after the starting date");
            }),
              product_id: Yup.string().required("Product is required"),
              type_of_bid: Yup.string().required("Type of bid is required")
            })
          },
          {
            component: PriceForm,
            validationSchema: Yup.object().shape({
              starting_price: Yup.string().required("Starting price  is required"),
              ending_price: Yup.number().required("Ending price is required").test(
              'is-greater','Ending price must be greater than starting price',function (value) {
                const { starting_price } = this.parent;
                return value > starting_price;
                }
                ),
               currency: Yup.string().required("Currency  is required"),
            })
          },
          {
            component: AddressForm,
            validationSchema: Yup.object().shape({
              region_id: Yup.string().required("Region is required"),
              district_id: Yup.string().required("District is required"),
              ward_id: Yup.string().required("Ward  is required"),
              street_id: Yup.string().required("Street  is required"),
              physical_address: Yup.string().required("Street  is required"),
            })
          },
        ]}
      >
        {({
          currentStepIndex,
          renderComponent,
          handlePrev,
          handleNext,
          isNextDisabled,
          isPrevDisabled,
          isLastStep
        }) => {
          return (
            <>
              <Steps currentStepIndex={currentStepIndex} />
              {renderComponent()}
              <div className="buttons flex justify-content-center">
                {currentStepIndex === 0 ? <></>:<>
                 <button
                  className="button is-primary"
                  disabled={isPrevDisabled}
                  onClick={handlePrev}
                >
                  Previous
                </button>
                </>}
               
                <button
                  className="button is-primary"
                  disabled={isNextDisabled}
                  onClick={handleNext}
                >
                  {isLastStep ? "Submit" : "Next"}
                </button>
              </div>
            </>
          );
        }}
      </FormikWizard>
    </div>
  );
}
